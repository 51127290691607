import { Backdrop, Box, Button, Checkbox, CircularProgress, Divider, Fade, FormControl, FormControlLabel, FormGroup, Modal, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react"
import Layout from "../../components/shared-layout";
import Breadcrumb from "../../components/shared-breadcrumb";
import useStores from "../../hooks/use-stores"
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import { navigate } from "gatsby";
import { GetCurrentConvertedDateTime, isBrowser, ProdEnvCheck, questionType } from "../../constants/options";
import { toJS } from "mobx";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";

const ExamAttemptPage = observer(({ location }) => {
  const { examStore } = useStores();
  const [examQuestions, setExamQuestions] = useState<any>([]);
  const [knowlegePointLabels, setKnowlegePointLabels] = useState<any>([]);
  const [answerList, setAnswerList] = useState<any>([]);
  const [mark, setMark] = useState<string>("");
  const correctAnswerStyle = "border-2 border-l-8 border-green-400 my-1 pl-4 rounded-md";
  const incorrectAnswerStyle = "border-2 border-l-8 border-red-400 my-1 pl-4 rounded-md";

  useEffect(() => {
    if (location.state && location.state.attemptId) {
      examStore.getStudentExamResponseDetailByAttemptId(location.state.attemptId)
        .then((res) => {
          setExamQuestions(res);
          if (res[0].knowledgePoint !== "") {
            setKnowlegePointLabels([...new Set(res.map(q => q.knowledgePoint))]);
          } else {
            setKnowlegePointLabels([""]);
          }
          let totalMarks = res.reduce((s, a) => s + a.fullMark, 0);
          let studentMarks = res.reduce((s, a) => s + parseInt(a.studentMark), 0);
          setMark(`${studentMarks}/${totalMarks}`);
          let tempAnswerList: any[] = [];
          res.map((q: any) => tempAnswerList.push({
            questionId: q.id,
            response: q.type === questionType.multiple ? q.studentAnswer.split(',') : q.studentAnswer,
            fullMark: q.fullMark
          }));
          console.log(tempAnswerList);
          setAnswerList(tempAnswerList);
        })
    }
  }, [location]);

  return (
    <ContentLayout
      pageName={t('MY_ATTEMPT')}
      pageHeading={t('MY_ATTEMPT')}
      breadCrumb={[
        { label: t('EXAM'), to: "" },
        { label: t('MY_EXAM'), to: "/exam" },
        { label: t('MY_ATTEMPT'), to: "" }
      ]}
      showHeadingButton={false}
      inlineOperations={
        <Typography variant="h5" fontWeight={'bold'}>
          {t('MARKS_A')}: {mark}
        </Typography>
      }
    >
      {isBrowser && location.state && location.state.attemptId &&
        <>
          {examStore.loading || examQuestions.length == 0 ?
            <div className="text-center py-20">
              <CircularProgress className="text-theme" />
              <Typography>{t('LOADING_EXAM')}...</Typography>
            </div>
            :
            <>
              <div className="grid grid-cols-7">
                {knowlegePointLabels.length > 0 &&
                  knowlegePointLabels.map((kpl: string, kplIndex: number) => (
                    <>
                      {/* Knowledge Area */}
                      <div className="col-span-1">
                        <div className="border-r-8 border-theme pr-4">
                          <Typography
                            variant="h5"
                            marginBottom={2}
                            sx={{ textDecoration: "underline" }}
                            textAlign="right"
                          >
                            {kpl === "" ? t('EXAM_SINGLE') : kpl}
                          </Typography>
                        </div>
                      </div>

                      {/* Question Area */}
                      <div className="col-span-6 border-l px-4">
                        {examQuestions.filter(q => q.knowledgePoint === kpl)
                          .map((question: any, index: number) => (
                            <div key={`on_exam_question_${index}`} className="bg-white p-4 rounded-lg mb-4">
                              <Typography variant="h6" marginBottom={1}>
                                {index + 1}. {question.detail} <span className="text-sm">({question.fullMark} {question.fullMark > 1 ? "marks" : "mark"})</span>
                              </Typography>
                              <Typography variant="body2" marginBottom={1} width={"100%"} fontWeight="bold">
                                <span className={`${question.studentMark == question.fullMark ? "text-green-500"
                                  : question.studentMark == 0 ? "text-red-500" : "text-orange-500"}`}>
                                  {question.studentMark == question.fullMark ? `${t('WELL_DONE')} (${question.studentMark}${question.studentMark > 1 ? t('MARKS_B') : t('MARK_B')})`
                                    : question.studentMark == 0 ? `${t('INCORRECT')} (0${t('MARK_B')})`
                                      : `${t('PARTIAL_CORRECT')} (${question.studentMark}${t('MARKS_B')})`}
                                </span>
                              </Typography>
                              {question.type === questionType.single ?
                                <FormControl key={`question_option_${index}`}>
                                  <RadioGroup
                                    aria-labelledby="controlled-radio-buttons-group"
                                    name="single-multiple-choice-group"
                                    value={answerList.find(q => q.questionId == question.id) ? parseInt(answerList.find(q => q.questionId == question.id).response) : 0}
                                  >
                                    {question.option.map((o: any, index: number) => (
                                      <div className={`${o.isCorrect ? correctAnswerStyle : !o.isCorrect && question.studentAnswer == o.id && incorrectAnswerStyle}`}>
                                        <FormControlLabel
                                          value={o.id}
                                          control={<Radio />}
                                          disabled
                                          label={<Typography variant="body2" color={"#626262"}>
                                            {o.detail} {o.isCorrect ?
                                              <span className="text-green-400"><CheckCircleIcon color="inherit" sx={{ marginTop: -0.5 }} /></span>
                                              : !o.isCorrect && question.studentAnswer == o.id &&
                                              <span className="text-red-500"><CancelIcon color="inherit" sx={{ marginTop: -0.5 }} /></span>}
                                          </Typography>}
                                        />
                                      </div>
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                                : question.type === questionType.multiple ?
                                  <FormGroup key={`question_option_${index}`}>
                                    {question.option.map((o: any, index: number) => (
                                      <div className={`${o.isCorrect && correctAnswerStyle}`}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={
                                                answerList.find(q => q.questionId == question.id) &&
                                                  answerList.find(q => q.questionId == question.id).response.includes(o.id.toString())
                                                  ? true : false}
                                              disabled
                                            />}
                                          label={<Typography variant="body2" color={"#626262"}>
                                            {o.detail} {o.isCorrect ?
                                              <span className="text-green-400"><CheckCircleIcon color="inherit" sx={{ marginTop: -0.5 }} /></span>
                                              : !o.isCorrect && question.studentAnswer == o.id &&
                                              <span className="text-red-500"><CancelIcon color="inherit" sx={{ marginTop: -0.5 }} /></span>}
                                          </Typography>}
                                          name={o.id}
                                        />
                                      </div>
                                    ))}
                                  </FormGroup>
                                  : question.type === questionType.fillInBlank &&
                                  <TextField
                                    inputProps={{ 'aria-label': 'description' }}
                                    sx={{ marginBottom: 2, color: "#626262" }}
                                    value={answerList.find(q => q.questionId == question.id) &&
                                      answerList.find(q => q.questionId == question.id).response}
                                    disabled
                                    size="small"
                                  />
                              }
                              {/* {question.id != examQuestions[examQuestions.length - 1].id && <Divider sx={{ marginBottom: 1.5 }} />} */}
                            </div>
                          ))}
                      </div>
                    </>
                  ))}
              </div>
            </>
          }
        </>}
    </ContentLayout>
  )
});

export default ExamAttemptPage;
