import { Box, Divider, Paper, Typography } from "@mui/material";
import * as React from "react"
import { isBrowser, NavParentCategory, ProdEnvCheck } from "../constants/options";
import { THEME_BUTTON } from "../constants/style";
import useStores from "../hooks/use-stores"
import Breadcrumb from "./shared-breadcrumb";
import VmButton from "./shared-button";
import ChildNav from "./shared-child-nav";
import Layout from "./shared-layout";

// markup
const ContentLayout = ({
  pageName, pageHeading, breadCrumb, showHeadingButton = true, onClickAction = () => { }, disabledButton = false, buttonLabel = "", inlineOperations = null,
  children, enableChildNav = true
}) => {
  return (
    <Layout pageName={pageName}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: 'fit-content'
      }}>
        {/* {breadCrumb.length != 0 &&
          <div className="bg-white p-3 rounded-lg mr-4" style={{ height: 52 }}>
            <Breadcrumb linkList={breadCrumb} />
          </div>} */}
        {enableChildNav && <ChildNav showPageTitleOnly={false} navCategory={isBrowser && ProdEnvCheck([localStorage.MENU])
          ? localStorage.MENU : NavParentCategory.DASHBOARD} />}
      </div>
      <Box sx={{ marginTop: breadCrumb.length == 0 ? 0 : 1 }} />
      {/* <Paper elevation={1} className="p-4"> */}
      <div className={`flex justify-between ${breadCrumb.length == 0 ? "mt-2" : "mt-6"}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'justify-between'
        }}>
        <Typography fontWeight={'bold'} marginBottom={3}
          className="lg:text-lg 2xl:text-2xl"
          sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
          {pageHeading}
        </Typography>
        {showHeadingButton &&
          <VmButton
            className="text-white px-4 py-2 mb-4 lg:text-sm 2xl:text-base"
            style={{ height: 'fit-content', backgroundColor: disabledButton ? "gray" : THEME_BUTTON }}
            disabled={disabledButton}
            onClick={onClickAction}
          >
            {buttonLabel}
          </VmButton>}
        {inlineOperations && inlineOperations}
      </div>
      {/* <Divider sx={{ marginY: 2 }} /> */}
      {children}
      {/* </Paper> */}
    </Layout>
  )
}

export default ContentLayout;
